import React from 'react';
import scissors from '../../svg/scissors.svg'
import Challenge from './Challenge';
import Divider from '../Divider';


const challenges = [
  {
    number: 1,
    title: 'Prioritization',
    description:
      'Managing a product roadmap is challenging, especially when goals are spread across multiple teams and changing priorities.',
  },
  {
    number: 2,
    title: 'Deadlines',
    description:
      'It’s easy to lose track of what’s planned for each quarter, miss critical deadlines, or fail to spot projects at risk.',
  },
  {
    number: 3,
    title: 'Clarity',
    description:
      'Without a clear, organized roadmap, your product development can quickly become chaotic and unpredictable.',
  },
];



const ChallengesSection: React.FC = () => {
  return (
      
      <div className="mt-[103px] px-4">
        <div className='mb-[16px]'>
          <img src={scissors} alt='scissors' />
        </div>
        <div className="text-[32px] font-bold max-w-[744px]">
          <span className="text-blue-500">Disconnected</span>
          <span className="text-black"> Goals & Hard-to-Track Progress.</span>
        </div>

        <div className="flex flex-col md:flex-row justify-around text-center mt-[57px] gap-6">
          {challenges.map((challenge) => (
            <Challenge key={challenge.number} {...challenge} />
          ))}
        </div>
        <Divider className='mt-[63px]' />
      </div>
  );
};

export default ChallengesSection;
