import React from "react";

interface SectionProps {
  imgsrc: string;
  sectionTitle: string;
  sectionDescription: string;
  titleColor: string;
  className?: string;
  textSectionProps?: any;
}

const Section: React.FC<SectionProps> = ({
  imgsrc,
  sectionTitle,
  sectionDescription,
  titleColor,
  className,
  textSectionProps
}) => {
  return (
    <div className="px-4 lg:px-10">
      <div className={`flex flex-col xl:flex-row xl:items-center justify-between gap-6 ${className}`}>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-auto-fit gap-6 w-[100%] basis-9/12"
          style={{ gridTemplateColumns: "repeat(auto-fit, minmax(336px, 1fr))", transform: 'scale(90%)' }}>
          <img src={imgsrc} className="h-[140%]" alt={"proactive risk mitigation process"} />
        </div>
        <div className="mt-6 xl:ml-10 max-w-[400px]" {...textSectionProps}>
          <h3 className={`${titleColor} text-lg lg:text-lg font-bold mb-4`}>
            {sectionTitle.toUpperCase()}
          </h3>
          <p className="text-gray-600 text-md lg:text-base max-w-[400px]">
            {sectionDescription}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section;
