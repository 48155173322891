import React from 'react'
import RoadMapsHeroSection from '../components/roadmaps/RoadmapsHeroSection'
import ChallengesSection from '../components/roadmaps/ChallengesSection'
import FeaturesSection from '../components/roadmaps/FeaturesSection'
import RisksSection from '../components/roadmaps/RisksSection'
import TransparencySection from '../components/roadmaps/TransparencySection'
import VisualProgressSection from '../components/roadmaps/VisualProgressSection'
import PremiumSection from '../components/roadmaps/PremiumSection'
import RoadmpasCallToAction from '../components/roadmaps/RoadmapsCallToAction'

const Roadmaps = () => {
  return (
    <div className="App min-h-[calc(100vh-273px)] flex flex-col mx:auto relative mx-4 md:mx-14 lg:mx-24">
      <RoadMapsHeroSection />
      <ChallengesSection />
      <FeaturesSection />
      <RisksSection />
      <TransparencySection />
      <VisualProgressSection />
      <PremiumSection />
      <RoadmpasCallToAction />
    </div>
  )
}

export default Roadmaps