import Button from "../Button";

// components/CallToAction.jsx
const RoadmpasCallToAction = () => {
  return (
    <section className="py-16 bg-white text-center">
      <div className="max-w-4xl mx-auto text-[#0C0C0C]">
        {/* Headline */}
        <h2 className="text-[46px] font-bold leading-[54.89px]">Like what you see?</h2>
        <p className="text-[32px] font-bold leading-[38.19px] mt-[20px]">Join us today.</p>

        {/* Button */}
        <div className="mt-6">
          <Button className="rounded-md w-[332px] h-[49px]" onClick={() => window.location.assign("https://app.topigon.com/register")}>
            Get Started
          </Button>
        </div>

      </div>
    </section>
  );
};

export default RoadmpasCallToAction;
