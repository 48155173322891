import React from "react";
import Button from "./Button";

type HeroSectionProps = {
  title: string;
  subtitle?: string;
  imageSrc: string;
  buttonText?: string;
  onButtonClick?: () => void;
  buttonClassName?: string;
  imageAlt?: string;
  renderChip?: React.ReactNode
};

const HeroSection: React.FC<HeroSectionProps> = ({
  title,
  subtitle,
  imageSrc,
  buttonText = "Get Started",
  onButtonClick,
  buttonClassName = "rounded-lg w-[80%] xs:w-[332px] h-[49px] mt-[59px]",
  imageAlt = "hero image",
  renderChip
}) => {
  return (
    <div className="pt-16 px-4 text-center">
      <div className="flex flex-col sm:flex-row justify-between">
        <div className="">
          {renderChip}
          <h1 className="text-4xl font-bold my-4">{title}</h1>
        </div>
        {subtitle && (
          <p className="text-xl text-gray-600 mb-8 text-center sm:text-left self-center sm:self-end max-w-[273px] mt-[14px]">
            {subtitle}
          </p>
        )}
      </div>

      <div className="relative mx-auto max-w-4xl">
        <img src={imageSrc} alt={imageAlt} />
      </div>

      {buttonText && (
        <Button className={buttonClassName} onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default HeroSection;
