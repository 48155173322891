import topigonLogo from '../../svg/topigonLogo.svg'
import shareRoadmaps from '../../svg/share-roadmaps.svg'
import unlimitedRoadmaps from '../../svg/unlimited-roadmaps.svg'
import Divider from '../Divider';

const PremiumSection = () => {
  return (
    <section className="mt-[74px]">
      <div className="max-w-6xl mx-auto text-center px-4">
        <div className="">
          <div className="flex justify-center items-center mb-[46px]">
            <img src={topigonLogo} alt="logo" />
          </div>
          <h2 className="text-blue-600 text-[46px] font-bold leading-[54.89px]">Premium</h2>
          <p className="text-[#0C0C0C] text-[46px] font-bold leading-[54.89px]">Upgrade to make it your own.</p>
        </div>

        <div className='flex flex-col md:flex-row items-center md:justify-center gap-8 mt-[87px]'>
            <div>
                <img src={unlimitedRoadmaps} alt="unlimited-roadmaps" />
            </div>
            <div>
                <img src={shareRoadmaps} alt="share-roadmaps" />
            </div>
        </div>

        {/* Call to Action */}
        <div className="mt-[87px]">
            <a
            href="/pricing"
            className="text-blue-600 font-semibold underline-offset-4 underline text-lg"
            >
            See Pricing
            </a>
        </div>
      </div>
      <Divider className='mt-[63px]' />
    </section>
  );
};

export default PremiumSection;
