import React from "react";

type DescriptionSectionProps = {
  title: string;
  description: string;
};

export type IconDescriptionSectionProps = {
  icon: string;
  descriptionSection: DescriptionSectionProps;
};

const IconDescriptionSection: React.FC<IconDescriptionSectionProps> = ({
  icon,
  descriptionSection,
}) => {

  return (
    <div className="flex flex-col justify-center items-center gap-8 py-8">
      <div className="flex flex-1 justify-center items-center w-full">
        <img src={icon} alt="Another Icon" />
      </div>

      <div className={`w-full flex flex-col sm:flex-row flex-1 mx-auto max-w-[1125px] py-16`}>
        <h3 className="text-xl basis-1/2 font-semibold mb-2">{descriptionSection.title}</h3>
        <p className="text-darkGray font-medium basis-1/2 text-lg">{descriptionSection.description}</p>
      </div>
    </div>
  );
};

export default IconDescriptionSection;
