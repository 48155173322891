import React from 'react';
import featureIcon from '../../svg/feature-icon.svg'
import FeatureCard from './FeatureCard';
import easyIntuitive from '../../svg/easy-intuitive.svg'
import organized from '../../svg/organized.svg'

const features = [
  {
    id: 1,
    icon: easyIntuitive,
    title: 'Easy. Intuitive.',
    description:
      'Break your product goals into quarterly initiatives, visualize your plan for the entire year, and easily adjust as priorities change.',
  },
  {
    id: 2,
    icon: organized,
    title: 'Organized.',
    description:
      'Keep every feature project organized with milestones and track progress at every step.',
  },
];

const FeaturesSection: React.FC = () => {
  return (
    <div className="mt-[87px] px-4">
        <div className='flex flex-col-reverse sm:flex-row item items-center sm:justify-between'>
            <h2 className="text-3xl lg:text-3xl xl:text-4xl font-bold mb-6">Plan. Track. Stay Aligned.</h2>
            <div className='-mt-[40px]'>
                <img src={featureIcon} alt="feature-icon" />
            </div>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-10">
          {features.map((feature) => (
            <FeatureCard key={feature.id} {...feature} />
          ))}
        </div>
        <div className='flex justify-center mt-[47px]'>
          <button className='underline underline-offset-2 font-semibold text-[#1A73E8]' onClick={() => window.location.assign("https://app.topigon.com/register")}>Get Started for Free</button>
        </div>
    </div>
  );
};

export default FeaturesSection;
