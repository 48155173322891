import React from "react";
import stayAhead from "../../svg/stay-ahead.svg"
import knowWhereYouAre from "../../svg/know-where-you-are.svg"
import cards from "../../svg/cards.svg"
import IconDescriptionSection, { IconDescriptionSectionProps } from "./IconDescriptionSection";
import Divider from "../Divider";


const sectionsData: IconDescriptionSectionProps[] = [
  {
    icon: knowWhereYouAre,
    descriptionSection: {
      title: "Visual Progress Tracking.",
      description:
        `With clear indicators for each state, you always know where your product initiatives stand. “Delayed” alerts help you course-correct quickly, “On Track” keeps you steady, and “Ahead” means you’re ready to push forward.`,
    },
  },
  {
    icon: stayAhead,
    descriptionSection: {
      title: "Stay Ahead of Surprises.",
      description:
        "Your roadmap isn’t static—it moves with your projects. Topigon helps you see if you're delayed, on track, or ahead of schedule at a glance. This lets you take proactive action to stay aligned with your goals.",
    },
  },
  {
    icon: cards,
    descriptionSection: {
      title: "Map the Road Ahead.",
      description:
        "Initiatives provide a clear path forward, breaking down your big goals into actionable steps. They ensure your team stays aligned, focused, and prepared to see projects through to success with confidence.",
    },
  },
];

const VisualProgressSection: React.FC = () => {
  return (
    <>
      <section className="py-8 px-4">
        {/* Section Heading */}
        <h2 className="text-2xl md:text-3xl font-bold text-center pt-12">
        Behind, On Time, or Ahead —{" "}
        <span className="text-primary">Know</span>
        {" "}where you stand.
      </h2>

        {/* Description Section */}
        <div className="flex flex-col gap-8 mt-[80px]">
          {sectionsData.map((section, index) => (
          <IconDescriptionSection
            key={index}
            icon={section.icon}
            descriptionSection={section.descriptionSection}
          />
        ))}
        </div>
      </section>
      <Divider className='mt-[24px]' />
    </>
    
  );
};

export default VisualProgressSection;
