import React from "react";

interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string; // Allows additional Tailwind classes
}

const Divider: React.FC<DividerProps> = ({ className = "", ...props }) => {
  return (
    <div
      className={`border-b border-gray-200 ${className}`}
      {...props}
    ></div>
  );
};

export default Divider;