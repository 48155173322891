import React from "react";

interface FeatureCardProps {
  icon: string;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <div className="flex p-4 border rounded-xl shadow-sm h-[162px] w-full sm:w-[90%]  lg:max-w-[48%]">
        <img src={icon} alt={`${title} Icon`} width="98px" height="99px"  />
        <div className="flex flex-col justify-center pl-8">
            <h3 className="text-lg font-bold text-black">{title}</h3>
            <p className="text-gray-600 text-md">{description}</p>
        </div>
    </div>
  );
};

export default FeatureCard;
