import React from "react";
import Section from "./Section";
import transparencyimage from "../../svg/transparency-image.svg"
import Divider from "../Divider";

const TransparencySection: React.FC = () => {
  return (
    <>
        <Section
        imgsrc={transparencyimage}
        sectionTitle="Transparency"
        sectionDescription="From solo founders to startup teams, ensure they have a clear understanding of the big picture."
        titleColor="text-green-600"
        className="flex-col-reverse justify-start xl:-ml-[6rem] mb"
        textSectionProps={{className:'xl:basis-4/12 ml-[1.3rem] xl:ml-0'}}
        />
        <Divider className='mt-[120px]' />
    </>
  );
};

export default TransparencySection;
