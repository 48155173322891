import React from 'react';
import roadMapsHeroImage from '../../svg/roadmaps-hero-image.svg'
import HeroSection from '../HerroSection';



const RoadMapsHeroSection: React.FC = () => {
  return (
    <HeroSection
      title="Roadmaps."
      subtitle="Visualize your product goals and track progress over time."
      imageSrc={roadMapsHeroImage}
      buttonText="Get Started"
      onButtonClick={() => window.location.assign("https://app.topigon.com/register")}
    />
  );
};

export default RoadMapsHeroSection;