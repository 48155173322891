import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './components/Header';
import Footer from "./components/Footer";
import Home from './components/Home';
import ScrollToTop from './components/ScrollToTop';
import TermsAndConditions from "./components/ContentTermsAndConditions";
import PrivacyPolocy from "./components/ContentPrivacyPolicy";
import NotFound from "./pages/NotFound";
import Roadmaps from './pages/Roadmaps';

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <div className="App min-h-screen flex flex-col mx:auto justify-center relative">
        <Routes>
          {/* Define your routes here */}
          <Route path="/" element={<Home />} />
          <Route path="use-cases/roadmaps" element={<Roadmaps />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolocy />} />
          {/* Add more routes as needed */}

          {/* Not Found Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
