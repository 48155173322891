import React from "react";
import Section from "./Section";
import riskimage from "../../svg/risk-image.svg";


const RisksSection: React.FC = () => {
  return (
  <>
    {/* Section Heading */}
    <h2 className="text-2xl md:text-3xl font-bold text-center mt-28">
        Spot Risks Before They Become Problems.
    </h2>
    <Section
      imgsrc={riskimage}
      sectionTitle="Proactive"
      sectionDescription="Know which initiatives are at risk before they derail your plan, so you can keep everything on track without last-minute surprises."
      titleColor="text-red-600"
      className="flex-col-reverse xl:flex-row-reverse mt-16 mb-[144px]"
      textSectionProps={{className: 'ml-[1.3rem] xl:ml-0'}}
    />
  </>
  );
}; 

export default RisksSection;

