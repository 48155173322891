interface FeatureProps {
  number: number;
  title: string;
  description: string;
}

const Challenge: React.FC<FeatureProps> = ({ number, title, description }) => {
  return (
    <div className="flex flex-col text-start">
      <div className="text-4xl font-mono text-black mb-[18px]">
        {number}
      </div>
      <h3 className="text-[22px] font-[600] text-black mb-[15px]">
        {title}
      </h3>
      <p className="text-gray-600 text-lg font-normal">
        {description}
      </p>
    </div>
  );
};

export default Challenge;